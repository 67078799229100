import { formatNumber } from '@angular/common';
import { Injectable, signal } from '@angular/core';
import { HashMap, LoadOptions, TranslateParams, TranslocoScope, TranslocoService } from '@ngneat/transloco';
import { es as esES } from 'date-fns/locale';
import { NzI18nInterface, NzI18nService, es_ES } from 'ng-zorro-antd/i18n';

type AvailableLanguage = 'es-MX';

const nzLangs: Record<AvailableLanguage, NzI18nInterface> = {
  'es-MX': es_ES, // Using es-ES because es-MX is not available directly, it can be modified as needed
};

const dateFnsLocales: Record<AvailableLanguage, Locale> = {
  'es-MX': esES, // Using es-ES because es-MX is not available directly, it can be modified as needed
};

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  activeLang$ = this.translocoService.langChanges$;
  title = signal('');

  constructor(
    private translocoService: TranslocoService,
    private nzI18nService: NzI18nService,
  ) {}

  load(path: string, options?: LoadOptions) {
    return this.translocoService.load(path, options);
  }

  setActiveLang(lang: AvailableLanguage) {
    this.nzI18nService.setLocale(nzLangs[lang]);
    this.nzI18nService.setDateLocale(dateFnsLocales[lang]);

    this.translocoService.setActiveLang(lang);
  }

  /**
   * Gets the instant translated value of a key
   *
   * @link https://ngneat.github.io/transloco/docs/translation-api#translate
   */
  translate<T = any>(key: TranslateParams, params?: HashMap, lang?: string) {
    return this.translocoService.translate<T>(key, params, lang);
  }

  /**
   * Gets the translated value of a key as observable
   *
   * @link https://ngneat.github.io/transloco/docs/translation-api#selecttranslate
   */
  selectTranslate<T = any>(
    key: TranslateParams,
    params?: HashMap,
    lang?: string | TranslocoScope,
    _isObject?: boolean,
  ) {
    return this.translocoService.selectTranslate<T>(key, params, lang, _isObject);
  }

  /**
   * Gets an object of translated keys as an observable
   *
   * @link https://ngneat.github.io/transloco/docs/translation-api#selecttranslateobject
   */
  selectTranslateObject<T = any>(key: string, params?: HashMap, lang?: string) {
    return this.translocoService.selectTranslateObject<T>(key, params, lang);
  }

  /**
   * Formats a number as text, with group sizing, separator, and other parameters based on the active lang
   */
  formatNumber(value: number) {
    return formatNumber(value, this.translocoService.getActiveLang());
  }
}
